var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('tr', {
    staticClass: "bg-white border border-black"
  }, [_c('td', {
    staticClass: "px-1 whitespace-no-wrap text-black"
  }, [_c('span', {
    staticClass: "text-xs text-black",
    staticStyle: {
      "font-size": "0.63rem",
      "line-height": "1.00rem"
    }
  }, [_vm._v(_vm._s(_vm.index + 1))])]), _c('td', {
    staticClass: "px-1"
  }, [_c('span', {
    staticClass: "text-xs whitespace-no-wrap text-black",
    staticStyle: {
      "font-size": "0.63rem",
      "line-height": "1.00rem",
      "white-space": "initial"
    }
  }, [_vm._v(_vm._s(_vm.product.product.name))])]), _c('td', {
    staticClass: "px-1 text-center whitespace-no-wrap text-black"
  }, [_c('span', {
    staticClass: "text-xs text-black",
    staticStyle: {
      "font-size": "0.63rem",
      "line-height": "1.00rem"
    }
  }, [_vm._v(_vm._s(_vm.product.quantity))])]), _c('td', {
    staticClass: "px-1 text-center whitespace-no-wrap text-black"
  }, [_c('span', {
    staticClass: "text-xs text-black",
    staticStyle: {
      "font-size": "0.63rem",
      "line-height": "1.00rem"
    }
  }, [_vm._v(_vm._s(_vm.product.unitPrice * _vm.product.quantity))])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }